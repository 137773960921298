import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export function MyModal({ show, onHide }) {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Bildirim</Modal.Title>
            </Modal.Header>
            <Modal.Body>SMS başarıyla gönderildi.</Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}
