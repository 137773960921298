import axios from 'axios';

const SmsSender = async (phoneNumber, message) => {
  const url = 'https://api.netgsm.com.tr/sms/send/xml';
  const userCode = process.env.REACT_APP_USER_CODE;
  const password = process.env.REACT_APP_PASSWORD;

  // XML formatında istek gövdesi hazırlama
  const xmlData = `
    <mainbody>
      <header>
        <company dil='TR' >Netgsm</company>
        <usercode>${userCode}</usercode>
        <password>${password}</password>
        <startdate></startdate>
        <stopdate></stopdate>
        <type>1:n</type>
        <msgheader>RMOS</msgheader>
      </header>
      <body>
        <msg><![CDATA[${message}]]></msg>
        <no>${phoneNumber}</no>
      </body>
    </mainbody>
  `;

  try {
    const response = await axios.post(url, xmlData, {
      timeout: 5000 // 5 saniye timeout
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Sunucu tarafından bir hata döndü
      console.log('Sunucu hatası:', error.response.status, error.response.data);
    } else if (error.request) {
      // İstek gönderildi ama yanıt alınamadı
      console.log('Yanıt alınamadı:', error.request);
    } else {
      // İstek sırasında bir hata oluştu
      console.log('İstek hatası:', error.message);
    }
    // Hata detaylarını logla veya kaydet
    console.log(error);
    // Kullanıcıya uygun geri bildirim sağla veya işlem yap
    console.log(error);
  }

};

export default SmsSender;
