import React from "react";
import axios from "axios";
import { decryptToken } from "../Ecrypt/EcDc";

// updateGosterGizle fonksiyonu
export const updateGosterGizle = async (otelID, yeniDurum, callback) => {
    try {
        const storedEncryptedToken = localStorage.getItem("encryptedToken");
        const decryptedToken = decryptToken(storedEncryptedToken);

        const response = await axios.post("https://api.hotelssurvey.com/api/Default/UpdateHotelVisibility", {
            otelID: otelID,
            gosterGizle: yeniDurum
        }, {
            headers: {
                Authorization: `Bearer ${decryptedToken}`
            }
        });

        if (callback) {
            callback(); // fetchHotels fonksiyonunu çağır
        }
    } catch (error) {
        console.error("API çağrısı sırasında hata oluştu: ", error);
    }
};
