import React from "react";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Components/Login/Login";
import ProtectedRoute from "./Components/ProtectedRoute";
import SmsModal from "./Components/NetGsm/SmsOnay";
import HotelBackup from "./Components/HotelBacup/HotelBackup";
import { HotelsProvider } from "./Components/HotelBacup/HotelContext";
import SmsOnay from "./Components/NetGsm/SmsOnay";
import Deneme from "./Components/HotelBacup/Deneme";

function App() {
  return (
    <HotelsProvider> {/* HotelsProvider'ı Router'ın dışına yerleştirin */}
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/hotel-backup" element={
              <ProtectedRoute>
                <HotelBackup />
              </ProtectedRoute>
            } />
            <Route path="/sms-onay" element={
                <SmsOnay />
            } />
          </Routes>
        </div>
      </Router>
    </HotelsProvider>
    // <Deneme></Deneme>
  );
}

export default App;
