import React from 'react';
import { TiThSmall } from "react-icons/ti";
import { FaFileCircleCheck } from "react-icons/fa6";
import { RiFileCloseLine } from "react-icons/ri";
import { VscEyeClosed } from "react-icons/vsc";
import { CiWifiOn, CiWifiOff } from "react-icons/ci";

function FilterOptions({ filter, setFilter, data }) {
    const hotelCount = Object.keys(data).length;

    const getButtonClass = (value) => {
        return `btn ${filter === value ? 'btn-primary' : 'btn-light text-dark'}`;
    };

    const getIconColor = (value, activeColor, defaultColor) => {
        return filter === value ? activeColor : defaultColor;
    };

    const renderButtonLabel = (value, label) => {
        return filter === value ? `${label} (${hotelCount})` : label;
    };

    return (
        <div className="mb-3 d-flex flex-wrap">
            <div style={{margin: '8px 5px 5px 0'}}>
                <button className={getButtonClass('all')} onClick={() => setFilter('all')}>
                    <TiThSmall size={15} color={getIconColor('all', '#219ebc', '#255588')} />
                    {renderButtonLabel('all', 'Tümü')}
                </button>
            </div>
            <div className='m-2'>
                <button className={getButtonClass('allChecked')} onClick={() => setFilter('allChecked')}>
                    <FaFileCircleCheck size={20} color={getIconColor('allChecked', '#538d22', '#538d22')} />
                    {renderButtonLabel('allChecked', 'Yedekleme Alınan Oteller')}
                </button>
            </div>
            <div className='m-2'>
                <button className={getButtonClass('anyUnchecked')} onClick={() => setFilter('anyUnchecked')}>
                    <RiFileCloseLine size={20} color={getIconColor('anyUnchecked', '#ec8385', '#ec8385')} />
                    {renderButtonLabel('anyUnchecked', 'Yedekleme Alınmayan Oteller')}
                </button>
            </div>
            <div className='m-2'>
                <button className={getButtonClass('ignored')} onClick={() => setFilter('ignored')}>
                    <VscEyeClosed size={20} color={getIconColor('ignored', '#ff8500', '#ff8500')} />
                    {renderButtonLabel('ignored', 'Yok Sayılanlar')}
                </button>
            </div>
            <div className='m-2'>
                <button className={getButtonClass('working')} onClick={() => setFilter('working')}>
                    <CiWifiOn size={20} color={getIconColor('working', '#538d22', '#538d22')} />
                    {renderButtonLabel('working', 'Servis Çalışan Oteller')}
                </button>
            </div>
            <div className='m-2'>
                <button className={getButtonClass('notWorking')} onClick={() => setFilter('notWorking')}>
                    <CiWifiOff size={20} color={getIconColor('notWorking', '#ec8385', '#ec8385')} />
                    {renderButtonLabel('notWorking', 'Servis Çalışmayan Oteller')}
                </button>
            </div>
        </div>
    );
}

export default FilterOptions;
