import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { encryptToken } from "../Ecrypt/EcDc";
import './Login.css';
import loginImage from '../images/login4.png'; // Resim yolu doğru olduğundan emin olun
import logo from '../images/rmos-vectörel-logo.png'; // Resim yolu doğru olduğundan emin olun
import Loader from "./Loader";
import { Modal } from "react-bootstrap";


function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  let apiToken = "";
  const [showSmsModal, setShowSmsModal] = useState(false); // SMS Modal durumunu yönetmek için
  const rowRef = useRef(null); // Ref oluşturma
  // SMS Modal'ı açma/kapama fonksiyonları
  const handleShowSmsModal = () => setShowSmsModal(true);
  const handleCloseSmsModal = () => setShowSmsModal(false);
  const [loading, setLoading] = useState(false); // Loader için yeni bir state
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Hata mesajı için yeni bir state
  const onSmsVerificationSuccess = () => {
    navigate('/sms-onay', { state: { username: username } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Loader'ı aktif et

    try {
      const response = await axios.post("https://api.hotelssurvey.com/api/Default/login", {
        username,
        password,
      });

      if (response.status === 200) {
        const encryptedToken = encryptToken(response.data.token);
        localStorage.setItem("encryptedToken", encryptedToken);
        localStorage.setItem("isLoggedIn", "true");
        setTimeout(() => {
          setLoading(false);
          onSmsVerificationSuccess();
        }, 500); // 1 saniye sonra Loader'ı kapat ve yönlendir
      } else {
        console.error("Giriş başarısız");
        setErrorMessage("Lütfen Kullnıcı adınızı ve şifrenizi kontrol ediniz");
        setTimeout(() => {
          setLoading(false);
          setShowErrorModal(true); // 1 saniye sonra Loader'ı kapat ve hata modalını göster
        }, 500);
      }
    } catch (error) {
      console.error("Giriş hatası:", error);
      setErrorMessage("Lütfen Kullnıcı adınızı ve şifrenizi kontrol ediniz");
      setTimeout(() => {
        setLoading(false);
        setShowErrorModal(true);
      }, 500);
    }
  };
  return (
    <div className="kutu  ">
      <div className="login-form h-100">
        <img src={loginImage} className="image" alt="Açıklama Metni" />
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              Hata
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body">{errorMessage}</div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
        <div className="container d-flex h-100 ">
          {loading && <Loader onComplete={() => setLoading(false)} />}
          <div ref={rowRef} className="row h-100 d-flex justify-content-center align-items-center  p-4">
            <div className=" col-lg-4 p-3 op" style={{ animation: 'fadeIn 1s ease-in-out' }}>

              <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logo} className="image2" alt="Login" />
                </div>
                <p className="text-center electrolize">Backup System</p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    class="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    class="form-control"

                  />
                </div>

                <div className="d-flex justify-content-center">
                  <button type="submit" class="btn btn-primary">Giriş Yap</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
