import React from 'react';
import { HashLoader } from 'react-spinners';
import './Login.css';

function Loader({ onComplete }) {
    // Set the color and size for the HashLoader
    const color = "#193b5e"; // You can choose any color you like
    const size = 50; // Size of the spinner

    return (
        <div className="loader-container">
            
            <HashLoader color={color} size={size} />
        </div>
    );
}

export default Loader;
