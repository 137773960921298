import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const encryptedToken = localStorage.getItem("encryptedToken");
    const smsVerified = localStorage.getItem('smsVerified') === 'true';

    if (!isLoggedIn || !encryptedToken ) {

        return <Navigate to="/login" replace />;
        
    }
    else if (!smsVerified) {
        return <Navigate to="/sms-onay" replace />;
    }

    return children; 
};

export default ProtectedRoute;
