import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Alert, Container, Row, Col, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import SmsSender from './NetGsm';
import './SmsModal.css'
const SmsOnay = () => {
    const defaultMessage = process.env.REACT_APP_MESSAGE;
    const location = useLocation();
    const username = location.state?.username.toUpperCase(); // useLocation ile username'i al
    const [username1, setUsername1] = useState('');
    const [randomCode, setRandomCode] = useState(null);
    const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
    const [countdown, setCountdown] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const firstInputRef = useRef(null); // İlk input için ref oluştur
    const [isValid, setIsValid] = useState(null);
    const [isChecking, setIsChecking] = useState(false);
    const [smsSent, setSmsSent] = useState(false);
    const [smsCount, setSmsCount] = useState(0);
    const [messageSent, setMessageSent] = useState(false);
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const encryptedToken = localStorage.getItem("encryptedToken");

    useEffect(() => {
        // Eğer kullanıcı giriş yapmamışsa, giriş sayfasına yönlendir
        if (!isLoggedIn || !encryptedToken) {
            navigate('/login');
        }
    }, [navigate, isLoggedIn, encryptedToken]);
    useEffect(() => {
        // Eğer randomCode set edilmişse, ilk input'a focus ol
        if (randomCode) {
            firstInputRef.current.focus();
        }
    }, [randomCode]); // randomCode değişikliklerini izle

    const getPhoneNumberForUser = (username) => {
        if (username == username1) {
            return;
        }
        setUsername1(username)
        return process.env[`REACT_APP_PHONE_NUMBER_${username.toUpperCase()}`];
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!messageSent) {
                handleSendSms();
            }
        }, 2000); // 2000 milisaniye = 2 saniye

        return () => clearTimeout(timer);

    }, []);


    const generateRandomCode = () => {
        return Math.floor(100000 + Math.random() * 900000);
    };

    const handleSendSms = async () => {
        if (!username) return; // SMS sayacı kontrolü

        try {
            setError('');
            setRandomCode(null);
            const code = generateRandomCode();
            setRandomCode(code);
            setCountdown(120);
            const userPhoneNumber = getPhoneNumberForUser(username);
            const personalizedMessage = `${username} Adlı Kullanıcı ${defaultMessage} - Your Code: ${code}`;
            await new Promise(resolve => setTimeout(resolve, 2000)); // 3 saniye bekle
            SmsSender(userPhoneNumber, personalizedMessage);
            setSmsCount(smsCount + 1); // SMS sayacını artır
            setSmsSent(true);
        } catch (err) {
            setError('Error sending SMS.');
            setSmsSent(false);
        }
    };


    const handleInputChange = (index, value) => {
        let newVerificationCode = [...verificationCode];
        newVerificationCode[index] = value;
        setVerificationCode(newVerificationCode);

        if (value.length === 1 && index < 5) {
            document.getElementById(`code-${index + 1}`).focus();
        } else if (value.length === 0 && index > 0) {
            document.getElementById(`code-${index - 1}`).focus();
        }
    };

    const handleVerificationSubmit = (e) => {
        e.preventDefault();
        setIsChecking(true); // Doğrulama işlemi başladı
        const code = verificationCode.join('');
        const isEmptyFieldPresent = verificationCode.some(field => field.trim() === '');

        if (isEmptyFieldPresent) {
            setError('Lütfen boş alanları doldurun.');
            setIsValid(false);
            setIsChecking(false);
            firstInputRef.current.focus();  // Boş alan varsa, ilk input'a focusla
        } else if (parseInt(code) === randomCode) {
            setIsValid(true);
            localStorage.setItem('smsVerified', 'true'); // SMS doğrulamasının başarılı olduğunu belirten bir değer kaydet
            setTimeout(() => {
                setVerificationCode(Array(6).fill('')); // Kutucukları temizle
                navigate('/hotel-backup'); // İlgili sayfaya yönlendir
            }, 1000); // 2 saniye bekle
        } else {
            setIsValid(false);
            setTimeout(() => {
                setVerificationCode(Array(6).fill('')); // Kutucukları temizle
                firstInputRef.current.focus();  // Doğrulama başarısız ise, ilk input'a focusla

            }, 500); // 2 saniye bekle
        }
    };

    const inputStyle = {
        width: '40px',
        height: '40px',
        display: 'inline-block',
        margin: '5px',
        textAlign: 'center',
        borderBottom: '3px solid #000',
        background: 'none',
        boxShadow: 'none',
        borderRadius: '0',
        fontSize: '24px',
        borderColor: isValid ? 'green' : isValid === false ? 'red' : '#000'
    };
    useEffect(() => {
        let interval = null;
        if (countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            // Sayacın sıfırlanmasına ilişkin işlemler
            setError('Kodun geçerlilik süresi doldu, lütfen tekrar kod isteyin.');
            setRandomCode(null);
            setCountdown(null);
        }
        return () => clearInterval(interval);
    }, [countdown]);

    const handlePaste = async (e) => {
        const text = await navigator.clipboard.readText();
        const code = text.match(/\d{6}/); // 6 haneli kodu regex ile bul
        if (code) {
            setVerificationCode(code[0].split(''));
            // Otomatik olarak inputlara yerleştir
        }
    };

    useEffect(() => {
        window.addEventListener('paste', handlePaste);
        return () => {
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <Container style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Card className='shadow-lg' style={{ maxWidth: '400px', textAlign: 'center', padding: '20px', borderRadius: '4px', backgroundColor: 'white', animation: 'fadeIn 1s ease-in-out' }}>
                <Card.Title><h2>2 Adımlı Doğrulama</h2></Card.Title>
                <Card.Body>
                    {randomCode == null ? (
                        <>
                            {error &&
                                <>
                                    <Alert variant="danger">{error}</Alert>
                                    <Button variant="primary" onClick={handleSendSms} style={{ borderRadius: '5px', marginTop: '20px' }}>
                                        Tekrar Kodu Gönder1
                                    </Button>
                                </>
                            }
                            {countdown === 0 && (
                                <Button variant="primary" onClick={handleSendSms} style={{ borderRadius: '5px', marginTop: '20px' }}>
                                    Tekrar Kodu Gönder1
                                </Button>
                            )}
                        </>
                    ) : (
                        <div>

                        </div>
                    )}
                    <Form onSubmit={handleVerificationSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Authentication code {countdown != null ? `(${countdown})` : ''}</Form.Label>
                            <div>
                                {verificationCode.map((code, index) => (
                                    <Form.Control
                                        ref={index === 0 ? firstInputRef : null} // Sadece ilk input için ref'i ata
                                        key={index}
                                        id={`code-${index}`}
                                        type="tel" // 'text' yerine 'tel' olarak değiştirildi, sayısal tuş takımını getirir
                                        pattern="[0-9]*" // Sadece sayısal girişlere izin ver
                                        inputMode="numeric" // 'type=tel' yeterli olmadığı durumlar için sayısal klavyeyi garanti altına alır
                                        style={inputStyle}
                                        maxLength="1"
                                        value={code}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                        {countdown > 0 && (
                            <Button variant="success" type="submit" style={{ borderRadius: '5px' }}>
                                Doğrula
                            </Button>
                        )}
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );

};

export default SmsOnay;