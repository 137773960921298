import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { decryptToken } from '../Ecrypt/EcDc';

export const HotelsContext = createContext();

export const HotelsProvider = ({ children }) => {
    const [hotels, setHotels] = useState([]);

    const fetchHotels = async () => {
        try {
            const storedEncryptedToken = localStorage.getItem("encryptedToken");
            const decryptedToken = decryptToken(storedEncryptedToken);

            const response = await axios.get("https://api.hotelssurvey.com/api/Default/Oteller", {
                headers: {
                    Authorization: `Bearer ${decryptedToken}`
                }
            });
            setHotels(response.data);
        } catch (error) {
            console.error("API isteği sırasında bir hata oluştu: ", error);
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchHotels, 60000); // 30 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []); // Empty dependency array to run only once on mount

    return (
        <HotelsContext.Provider value={{ hotels, fetchHotels }}>
            {children}
        </HotelsContext.Provider>
    );
};
