// encryption.js
import CryptoJS from "crypto-js";

// Token'ı Şifrele
export const encryptToken = (token) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  return CryptoJS.AES.encrypt(token, secretKey).toString();
};

// Şifreli Token'ı Şifre Çöz
export const decryptToken = (encryptedToken) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
