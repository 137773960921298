import React from 'react';

import { Radio } from 'react-loader-spinner';

export const WiFiAnimation = ({ color }) => {
  return (
    <Radio
      type="Radio"
      colors={color}  // Using the color prop
      height={30}
      width={30}
    />
  );
};
export default WiFiAnimation;
