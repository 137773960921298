import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { apiToken } from '../Login/Login.js';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS dosyasını içe aktarıyoruz
import { decryptToken } from "../Ecrypt/EcDc.js";
import { useNavigate } from "react-router-dom";
import { HotelsContext } from "./HotelContext.js";
import DataGrid from "./DataGrid.js";

function HotelBackup() {
    const navigate = useNavigate(); // Yönlendirme için useNavigate hook'unu kullanın
    const { hotels, fetchHotels } = useContext(HotelsContext);

    useEffect(() => {
        fetchHotels();
    }, []);
    
    useEffect(() => {
        // Geri gitmeyi engelleyen işlev
        const preventGoBack = () => {
            navigate("/hotel-backup", { replace: true });
        };

        // Geri gitme olayını dinleyen event listener ekle
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", preventGoBack);

        // Component unmount olduğunda event listener'ı kaldır
        return () => window.removeEventListener("popstate", preventGoBack);
    }, [navigate]);


     const handleLogout = () => {
        localStorage.removeItem("encryptedToken"); // Yerel depolamadaki tokenı sil
        localStorage.setItem("isLoggedIn", "false");
        navigate("/login"); // Kullanıcıyı giriş sayfasına yönlendir
    };

    return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <DataGrid data={hotels} /> {/* DataGrid bileşenini çağır ve veriyi iletle */}
            </div>
          </div>
        </div>
      );
      
}

export default HotelBackup;
