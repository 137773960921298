// filters.js
export const applyFilter = (otelData, filter, servisDurumlar, isServiceWorking) => {
    switch (filter) {
        case 'all':
            return true;
        case 'allChecked':
            return otelData.details.every(detail => detail.Local && detail.Cloud);
        case 'anyUnchecked':
            return otelData.details.some(detail =>
                (!detail.Local || !detail.Cloud) && detail.GosterGizle !== 1
            );
        case 'ignored':
            return otelData.GosterGizle === 1 || otelData.details.some(detail => detail.GosterGizle === 1);
        case 'working':
            return otelData.details.some(detail => isServiceWorking(detail.OtelKod, servisDurumlar));
        case 'notWorking':
            return otelData.details.some(detail => !isServiceWorking(detail.OtelKod, servisDurumlar));
        default:
            return true;
    }
};

export const isServiceWorking = (otelKod, servisDurumlar) => {
    const servisDurumu = servisDurumlar.find(item => item.OtelKod === otelKod)?.ServisDurumu;
    return servisDurumu !== undefined ? servisDurumu : false;
};