import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import WiFiAnimation from '../WifiLoader/WiFiAnimation';
import fetchServisDurumlari from './ServisDurumlari';
import { decryptToken } from '../Ecrypt/EcDc';
import { applyFilter, isServiceWorking } from './Filter';
import FilterOptions from './FilterOptions';
import { updateGosterGizle } from './HandleSwitchChange';
import { HotelsContext } from './HotelContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FcAcceptDatabase, FcDeleteDatabase } from "react-icons/fc";
import './DataGrid.css';
import SmsSender from '../NetGsm/NetGsm';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { MyModal } from './MyModal';
import sqlserver2012 from '../images/sql2012.png'
import sqlserver2008 from '../images/sql2008.png'
import sqlserver2014 from '../images/sql2014.png'
import sqlserver2016 from '../images/sql2016.png'
import sqlserver2017 from '../images/sql2017.png'
import sqlserver2019 from '../images/sql2019.png'
import sqlserver2022 from '../images/sql2022.png'
import { RiFileCloseLine } from 'react-icons/ri';

function groupByHotels(data) {
    return data.reduce((acc, item) => {
        const key = item.OtelKod; // Otel koduna göre gruplama
        acc[key] = acc[key] || { details: [], GosterGizle: item.GosterGizle, OtelAdi: item.OtelAdi };
        acc[key].details.push(item);
        console.log(acc)
        return acc;
    }, {});
}


function checkStatus(item, property) {
    if (item[property]) {
        return <FcAcceptDatabase size={30} icon={FcAcceptDatabase} style={{ color: 'green' }} />;
    } else {
        return <FcDeleteDatabase size={30} icon={FcDeleteDatabase} style={{ color: 'red' }} />;
    }
}

function DataGrid({ data }) {
    const [showModal, setShowModal] = useState(false);
    const [servisDurumlar, setServisDurumlar] = useState([]);
    const [openDetails, setOpenDetails] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('all');
    const groupedData = groupByHotels(data);
    const { fetchHotels } = useContext(HotelsContext);
    const [filteredData, setFilteredData] = useState({});
    const [show, setShow] = useState(false);
    const [otelAdi, setOtelAdi] = useState('');
    const [telNo, setTelNo] = useState('');
    const [showBulkSmsModal, setShowBulkSmsModal] = useState(false);
    const [bulkSmsHotels, setBulkSmsHotels] = useState([]);
    const [yedeklemeAlinmayan, setYedeklemeAlinmayan] = useState(false);
    const navigate = useNavigate(); // Yönlendirme için useNavigate hook'unu kullanın


    const toggleDetails = (OtelAdi) => {
        setOpenDetails(prevOpenDetails => ({
            ...prevOpenDetails,
            [OtelAdi]: !prevOpenDetails[OtelAdi]
        }));
    };
    // Menüden yedek alınmayan otelleri sürekli dinleyip yedek alınmayan buton tetiklenirse toplu mesaj gönder açılıyor 
    useEffect(() => {
        if (filter == 'anyUnchecked') {
            setYedeklemeAlinmayan(true)
        }
        else {
            setYedeklemeAlinmayan(false)
        }
    }, [filter]);
    // Menüden yedek alınmayan otelleri sürekli dinleyip yedek alınmayan buton tetiklenirse toplu mesaj gönder açılıyor 

    const handleClose = () => {

        setShow(false);
        setOtelAdi(''); // Set to default or retrieved value
        setTelNo(''); // Set to default or retrieved value

    };
    const handleShow = (oadi, tno) => {
        setShow(true);
        setOtelAdi(oadi); // Set to default or retrieved value
        setTelNo(tno); // Set to default or retrieved value
    };
    const fetchServisDurumlari = async () => {
        try {
            const storedEncryptedToken = localStorage.getItem("encryptedToken");
            const decryptedToken = decryptToken(storedEncryptedToken);

            const response = await axios.get("https://api.hotelssurvey.com/api/Default/OtelDurum", {
                headers: {
                    Authorization: `Bearer ${decryptedToken}`
                }
            });
            setServisDurumlar(response.data);
            return response.data;
        } catch (error) {
            console.error("API isteği sırasında bir hata oluştu: ", error);
            return {};
        }
    };

    useEffect(() => {
        fetchServisDurumlari();

        const interval = setInterval(fetchServisDurumlari, 30000);

        return () => clearInterval(interval);
    }, []);

    const getFilteredDataBySearchTerm = (dataToFilter) => {
        if (!searchTerm) return dataToFilter;
    
        return Object.keys(dataToFilter)
            .filter(OtelKod => {
                const otelAdi = dataToFilter[OtelKod].OtelAdi.toLowerCase();
                return otelAdi.includes(searchTerm.toLowerCase());
            })
            .reduce((newData, key) => {
                newData[key] = dataToFilter[key];
                return newData;
            }, {});
    };
    

    const getFilteredData = () => {
        const filteredByService = Object.keys(groupedData)
            .filter(OtelAdi => applyFilter(groupedData[OtelAdi], filter, servisDurumlar, isServiceWorking))
            .reduce((newData, key) => {
                newData[key] = groupedData[key];
                return newData;
            }, {});

        return getFilteredDataBySearchTerm(filteredByService);
    };

    useEffect(() => {
        setFilteredData(getFilteredData());
    }, [searchTerm, filter, servisDurumlar, data]);

    const getWiFiColor = (otelKod) => {
        const servisDurumu = servisDurumlar.find(item => item.OtelKod === otelKod)?.ServisDurumu;

        if (servisDurumu === undefined) {
            return ["#808080", "#AAAAAA", "#CCCCCC"];
        }
        return servisDurumu ? ["#245501", "#538d22", "#73a942"] : ["#de1616", "#ec4040", "#e56b6b"]; // Yeşil ya da kırmızı tonları
    };

    function getSqlVersion(otelKod) {
        // const otel = servisDurumlar.find(item => item.OtelKod === otelKod);
        // return otel ? (otel.SqlSurum !== null ? otel.SqlSurum : 'Bilgi Yok') : 'Bilinmiyor';
        const otel = servisDurumlar.find(item => item.OtelKod === otelKod);
        const sqlSurum = otel ? (otel.SqlSurum !== null ? otel.SqlSurum : 'Bilgi Yok') : 'Bilinmiyor';

        return (
            <div>
                {/* Eğer SQL sürümü "SQL Server 2012" ise görsel göster */}
                {sqlSurum === 'SQL Server 2008' && (
                    <img style={{ height: '25px' }} src={sqlserver2008} alt="SQL Server 2012 Görseli" />
                )}
                {sqlSurum === 'SQL Server 2012' && (
                    <img style={{ height: '25px' }} src={sqlserver2012} alt="SQL Server 2012 Görseli" />
                )}
                {sqlSurum === 'SQL Server 2014' && (
                    <img style={{ height: '25px' }} src={sqlserver2014} alt="SQL Server 2012 Görseli" />
                )}
                {sqlSurum === 'SQL Server 2016' && (
                    <img style={{ height: '25px' }} src={sqlserver2016} alt="SQL Server 2012 Görseli" />
                )}
                {sqlSurum === 'SQL Server 2017' && (
                    <img style={{ height: '25px' }} src={sqlserver2017} alt="SQL Server 2012 Görseli" />
                )}
                {sqlSurum === 'SQL Server 2019' && (
                    <img style={{ height: '25px' }} src={sqlserver2019} alt="SQL Server 2012 Görseli" />
                )}
                {sqlSurum === 'SQL Server 2022' && (
                    <img style={{ height: '25px' }} src={sqlserver2022} alt="SQL Server 2012 Görseli" />
                )}

                {/* Burada sqlSurum değerini de gösterebilirsiniz */}
                {/* <p> {sqlSurum}</p> */}
            </div>
        );
    }

    const handleSwitchChange = (otelID, mevcutDurum) => {
        const yeniDurum = mevcutDurum === 1 ? 2 : 1;
        updateGosterGizle(otelID, yeniDurum, fetchHotels); // fetchHotels fonksiyonunu callback olarak geçir
    };

    //renkbelilerme fonksiyonu 
    function getRowClassName(local, cloud) {
        return local && cloud ? 'green-row' : 'red-row';
    }

    function getDatabaseRowClassName(detail) {
        return detail.Local && detail.Cloud ? 'green-row' : 'red-row';
    }

    //renkbelilerme fonksiyonu 
    const SmsGonder = async (oteAdi, teNo) => {
        const mesaj = `${oteAdi.toUpperCase()} , \\nRmos Cloud Backup sisteminiz çalışmıyor.\\nRmos Yazılım ile iletişime geçiniz...`;
        SmsSender(teNo, mesaj);
        setOtelAdi('');
        setTelNo('');
        handleClose();
        setShowModal(true);
    };

    const handleLogout = () => {
        localStorage.removeItem("encryptedToken");
        localStorage.setItem("isLoggedIn", "false");
        localStorage.removeItem("smsVerified");
        navigate("/login");
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(/(\d{2})\.(\d{2})\.(\d{4}) (\d{2}:\d{2}:\d{2})/, '$1.$2.$3 - $4');
    }


    const handleBulkSms = () => {
        const filteredHotels = Object.keys(filteredData).filter(OtelAdi => {
            const otelData = filteredData[OtelAdi];
            const allLocal = otelData.details.every(detail => detail.Local);
            const allCloud = otelData.details.every(detail => detail.Cloud);

            // En az bir false varsa veya biri true diğeri false ise
            return !allLocal || !allCloud || (allLocal !== allCloud);
        }).map(OtelAdi => ({
            name: OtelAdi,
            phone: servisDurumlar.find(service => service.OtelKod === filteredData[OtelAdi].details[0]?.OtelKod)?.TelNo
        }));
        // Modalı aç ve filtrelenmiş otelleri gönder
        setShowBulkSmsModal(true);
        setBulkSmsHotels(filteredHotels);
    };

    return (
        <div className="container mt-4">
            <MyModal show={showModal} onHide={() => setShowModal(false)} />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Otel Bilgi Mesaj Gönder</Modal.Title>
                </Modal.Header>
                <Modal.Body> <span style={{ color: '#255588', fontSize: '20px' }}>{otelAdi.toUpperCase()}</span> Mesaj Göndermek <br></br> İstediğinizden Emin Misiniz ?</Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn btn-primary'
                        onClick={() => SmsGonder(otelAdi, telNo)}>
                        Evet Gönder !
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={showBulkSmsModal} onHide={() => setShowBulkSmsModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Toplu Mesaj Gönder</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className='overflow-auto' style={{ height: '250px' }}>
                        {bulkSmsHotels.map(hotel => (
                            <p className='m-2 bg-light shadow-sm' key={hotel.name}>{hotel.name} - {hotel.phone}</p>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        bulkSmsHotels.forEach(hotel => {
                            SmsGonder(hotel.name, hotel.phone);
                        });
                        setShowBulkSmsModal(false);
                    }}>
                        Mesajları Gönder
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='d-flex flex-wrap justify-content-between  align-items-center'>
                <div className=' '>
                    <button onClick={handleLogout} className="btn btn-primary btn-custom">Çıkış Yap</button>
                </div>
                <div className='electrolize1'>
                    <span style={{ color: '#255588', fontSize: '35px' }}>RM</span><span style={{ color: '#E02D39', fontSize: '35px' }}>OS</span>  <span style={{ fontSize: '25px' }}>Backup System</span>
                </div>

                <div className=''>
                    <input
                        type="text"
                        className="form-control  shadow-sm"
                        placeholder="Otel adına göre ara..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <FilterOptions filter={filter} setFilter={setFilter} data={filteredData} />

            {yedeklemeAlinmayan && (
                <div className='mb-2 d-flex justify-content-end'>
                    <button className="btn " style={{ backgroundColor: '#E02D39', color: 'white' }} onClick={handleBulkSms}><RiFileCloseLine size={20} color={'#fff'} /> Yedek Almayan Hotellere Toplu Mesaj Gönder</button>
                </div>
            )}


            <div className="table-responsive">
                <table className="table align-middle mb-0 bg-Primary">
                    <thead className="table-primary"> {/* İlk tablo için mavi başlık */}
                        <tr className='text-center'>
                            <th className='text-center'>Otel Adı</th>
                            <th className='text-center'>Local</th>
                            <th className='text-center'>Cloud</th>
                            <th className='text-center'>Sql server Sürümü</th>
                            <th className='text-center'>Servis Durumu</th>
                            <th className='text-center'>Tarih / Saat</th>
                            <th className='text-center'>IT Mesaj Gönder</th>
                            <th className='text-center'>Detaylar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(filteredData).map((OtelKod) => {
                            const otelData = filteredData[OtelKod];
                            const allLocal = otelData.details.every(detail => detail.Local);
                            const allCloud = otelData.details.every(detail => detail.Cloud);
                            const rowClassName = getRowClassName(allLocal, allCloud); // Sınıf adı belirleme
                            const otelKod = otelData.details[0]?.OtelKod; // İlk detaydan otel kodunu alıyoruz
                            const matchedService = servisDurumlar.find(service => service.OtelKod === otelKod);
                            const telNo = matchedService?.TelNo; // Eşleşen kaydın telefon numarasını alıyoruz                    
                            return (
                                <React.Fragment key={OtelKod}>
                                    <tr className='red-table'>
                                        <td className=''>
                                            <div className='d-flex'>
                                             <span className={rowClassName} ></span>{otelData.OtelAdi} {/*{otelKod} */}
                                            </div>
                                        </td>
                                        <td className='text-center '>{checkStatus({ GosterGizle: otelData.GosterGizle, Local: allLocal }, 'Local')}</td>
                                        <td className='text-center '>{checkStatus({ GosterGizle: otelData.GosterGizle, Cloud: allCloud }, 'Cloud')}</td>
                                        <td className='text-center '>{getSqlVersion(otelData.details[0]?.OtelKod)}</td>
                                        <td className='text-center '><WiFiAnimation color={getWiFiColor(otelData.details[0]?.OtelKod)} /></td>
                                        {/* <td className='text-center '>{formatDate(otelData.details[0]?.Tarih)}</td> */}
                                        <td className='text-center'>
                                            {/* {matchedService.ServisSinyali ? formatDate(matchedService.ServisSinyali) : "Serviceden sinyal alınamıyor"} */}
                                            {matchedService && matchedService.ServisSinyali ? (
                                                formatDate(matchedService.ServisSinyali)
                                            ) : (
                                                <span style={{ color: '#E02D39' }}>Servisden daha önce hiç <br></br> sinyal alınmadı !</span>
                                            )}

                                        </td>
                                        <td className='text-center'>
                                            {telNo && (
                                                // <button
                                                //     className='btn btn-primary'
                                                //     onClick={() => SmsGonder(OtelAdi, telNo)}>
                                                //     Mesaj Gönder
                                                // </button>

                                                <>
                                                    <button
                                                        className='btn btn-primary'
                                                        // onClick={handleShow}
                                                        onClick={() => handleShow(otelData.OtelAdi, telNo)}
                                                    >
                                                        Mesaj Gönder
                                                    </button>
                                                </>
                                            )}

                                        </td>
                                        <td className='text-center '>
                                            <button
                                                onClick={() => toggleDetails(otelData.OtelAdi)}
                                                className="btn btn-sm btn-toggle text-white "
                                                style={{ backgroundColor: openDetails[otelData.OtelAdi] ? "#de1616" : "#255588" }}
                                            >
                                                {openDetails[otelData.OtelAdi] ? '-' : '+'}
                                            </button>
                                        </td>

                                    </tr>

                                    {openDetails[otelData.OtelAdi] && (
                                        <tr>
                                            <td colSpan={8} className='ms-4 p-3'>
                                                <div className={`inner-table ${openDetails[otelData.OtelAdi] ? 'inner-table-opening' : 'inner-table-closing'}`}>
                                                    <table className="table align-middle mb-0 bg-white ms-4">
                                                        <thead className="table-success"> {/* İkinci tablo için yeşil başlık */}
                                                            <tr>
                                                                <th className='text-center'>Veritabanı</th>
                                                                <th className='text-center'>Local Durumu</th>
                                                                <th className='text-center'>Cloud Durumu</th>
                                                                <th className='text-center'>İndirme Linki</th>
                                                                <th className='text-center'>Yoksay</th>
                                                                <th className='text-center'>Yüklenme Tarihi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {otelData.details.map((detail, index) => (
                                                                <tr key={`${otelData.OtelAdi}-${index}`} >
                                                                    <td className='text-center'>
                                                                        <div className='d-flex'>
                                                                            <span className={getDatabaseRowClassName(detail)}></span>
                                                                            {detail.Veritabani}
                                                                        </div>
                                                                    </td>                                                                    <td className='text-center'>{checkStatus(detail, 'Local', true)}</td>
                                                                    <td className='text-center'>{checkStatus(detail, 'Cloud', true)}</td>
                                                                    <td className='text-center'>
                                                                        {detail.DownloadLink ? (
                                                                            <a href={detail.DownloadLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">
                                                                                Download
                                                                            </a>
                                                                        ) : (
                                                                            <span className="text-muted">İndirme linki yüklenmemiş</span>
                                                                        )}
                                                                    </td>
                                                                    <td className='text-center' style={{ cursor: 'pointer' }}>
                                                                        <div className="form-check form-switch" style={{ cursor: 'pointer' }}>
                                                                            <input
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`flexSwitchCheck-${detail.OtelID}`}
                                                                                checked={detail.GosterGizle === 1}
                                                                                onChange={() => handleSwitchChange(detail.OtelID, detail.GosterGizle)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`flexSwitchCheck-${detail.OtelID}`}>Yoksay</label>
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        { }
                                                                        {detail.Tarih ? (
                                                                            formatDate(detail.Tarih)
                                                                        ) : (
                                                                            <span style={{ color: '#E02D39' }}>Database Yüklenme Tarihi<br></br> Bulunamadı !</span>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default DataGrid;
